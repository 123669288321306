<template>
  <div class="m-grid p-page-role">
    <section class="p-list-search">
      <div class="p-input-search">
        <znl-input v-model="listQuery.Name" border clearable placeholder="角色名称" width="130px"></znl-input>
        <el-button class="m-grid-btn" type="primary" @click="onSearch()">查询</el-button>
        <el-button class="m-grid-btn" type="primary" @click="onOpenEdit">新增角色</el-button>
      </div>
    </section>
    <section class="m-grid-list">
      <grid
          ref="list"
          :columns="columns"
          :datas="itemSource"
          :onPageChanged="onSearch"
          :page-index="pagination.page"
          :page-size="pagination.size"
          :selection="false"
          :total-count="pagination.total"
          headerHeight="35"
          type="base"
      >
      </grid>
    </section>
  </div>
</template>

<script>
import { GetList, SetEnabled } from '@/api/role'
const columns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
    valueFormatter: ({ node: { rowIndex } }) => {
      return rowIndex + 1;
    }
  },
  {
    field: 'Name',
    headerName: '角色名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'Code',
    headerName: '识别码',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'Description',
    headerName: '描述',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
export default {
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      listQuery: {
        Name: ''
      },
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      }
    };
  },
  methods: {
    async onSearch(page = 1) {
      const { pagination } = this;
      const sendData = {
        ...this.listQuery,
        Page: page,
        Limit: pagination.size,
      };
      const data = await GetList(sendData); // 请求接口
      pagination.page = data.Page;
      pagination.total = data.Total;
      this.itemSource = data.Items;
    },
    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OptsColumn = columns.find((item) => item.field === "operation");
      if (OptsColumn) {
        OptsColumn.cellRendererParams = {
          buttons: function (param) {
            const { data: { IsEnabled } } = param;
            return [
              {
                text: "编辑",
                click(params) {
                  self.onOpenEdit(params.data);
                },
              },
              {
                text: IsEnabled ? '停用': '启用',
                click(params) {
                  self.onSetEnabled(params.data);
                },
              }
            ]
          }
        };
      }
      this.$refs.list.changeColumns(columns);
    },
    onSetEnabled(row) {
      const { Id, IsEnabled } = row
      const tips = IsEnabled? '停用': '启用'
      this.$confirm(`您确定${ tips }该角色?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = { Id, IsEnabled: !IsEnabled }
        SetEnabled(param).then(res=> {
          this.onSearch()
          this.$message({
            type: 'success',
            message: `${tips}成功!`
          });
        })
      }).catch(() => {
      });
    },
    onOpenEdit(item) {
      let id = item ? item.Id: 0
      this.$router.push({
        name: 'role-edit',
        query: {
          id: id
        }
      })
    }
  },
  created() {
    this.onSearch();
  },
  mounted() {
    this.initColumns();
  },
};
</script>

<style lang="scss">
.p-page-role {
  .p-input-search, .m-grid-search {
    margin-bottom: 10px;
  }
  .p-input-search > * {
    margin-right: 10px;
  }
}
</style>
